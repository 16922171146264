@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  /* font-family: sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fff, #fff);

  background-size: 10% 0.2rem;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
  animation-direction: reverse;
}

.link-underline-black {
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#ffab53, #ffab53);
}

.link-underline:hover {
  background-size: 100% 0.2rem;
  background-position: 0 100%;
}
/* .hoverLine h1 div {
  background: #00e700;
  width: 10%;
  transition: all 0.5s ease;
}
.hoverLine h1 div:hover {
  cursor: pointer;
}
.hoverLine h1 div:hover div {
  width: 100%;
} */
.jXZPOr {
  margin-left: 15rem;
}

/* Acoordian */

.accordion {
  margin: 30px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accordion__item {
  width: 50%;
  max-height: 25px;
  padding: 17px 10px;
  border-bottom: 1px solid #c9c9c9;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
}

.accordion__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion__header h4 {
  transition: 0.2s ease-in-out;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.accordion__header i {
  transition: 0.2s ease-in-out;
  transform-origin: center;
  margin-bottom: 10px;
}

.accordion__header:hover h4 {
  color: #10d6f5 !important;
}

.accordion__header:hover i {
  color: #10d6f5;
}

.accordion__content {
  margin: 5px;
}

@media (min-width: 900px) {
  .accordion__item {
    width: 40%;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 30px;
  }
  .accordion__item {
    width: 80%;
  }
}

/* arrow  */

.hero-svg:hover {
  fill: #10d6f5;
  animation: all;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

