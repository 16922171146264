.cls-1 {
  fill: #d0d1d3;
}
.cls-2 {
  fill: #f1f0f1;
}
.cls-3 {
  fill: #ffffff;
}
.cls-4 {
  fill: #fefefe;
}
.cls-5 {
  fill: #0978bd;
}
.cls-6 {
  fill: #f7ab50;
}
.cls-7 {
  fill: #0068c9;
}
#Indianapolis {
  z-index: 10;
}
#BC {
  .cls-1 {
    fill: #eaa959;
  }
}
#Alberta {
  fill: #eaa959;
}

.Marker {
  fill: #0068c9;

  animation: all 1s ease-in-out;
}
#logoZoomin {
  animation: all 1s ease-in-out;
}

// #Map {
//   #BC {
//     .cls-1 {
//       fill: #eaa959;
//     }
//     &:hover {
//       fill: #08c9;
//       transform: translate(0, -10px); // move the marker up 10px
//     }
//   }
// }
// #Map {
//   #Alberta {
//     fill: #eaa959;
//     &:hover {
//       fill: #08c9;
//       transform: translate(0, -10px); // move the marker up 10px
//     }
//   }
// }
#BC:hover + #MARKER-bc {
  transform: translate(0, -10px);
}
#Alberta:hover + #MARKER-alberta {
  transform: translate(0, -10px);
}

.gallery-wrap {
  width: 100%;
  min-height: 75vh;
  @media (min-width: 1360px) {
    min-height: 85vh;
  }
}
.svg-container {
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.item {
  flex: 1;
  margin: 0.2rem;
  height: 100%;
  background-position: left;
  background-size: cover;
  background-repeat: none;
  transition: flex 0.8s ease;

  &:hover {
    flex: 10;
  }
}
.itemM {
  flex: 1;
  margin: 0.2rem;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: none;
  transition: flex 0.8s ease;

  &:hover {
    flex: 3;
  }
}

.item-1 {
  background-image: url('https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/icons/trailers.png');
}

.item-2 {
  background-image: url('https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/icons/Parts.png');
}

.item-3 {
  background-image: url('https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/icons/tires.png');
}

.item-4 {
  background-image: url('https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/icons/freight.png');
}

.item-5 {
  background-image: url('https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/icons/randomtest-3.png');
}

.social {
  position: absolute;
  right: 35px;
  bottom: 0;

  img {
    display: block;
    width: 32px;
  }
}
.svg-container {
  width: 60%;
  @media (max-width: 1024px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}

// .swiper-pagination {
//   position: absolute;
//   bottom: 4rem !important;
//   right: 10px !important;
//   width: auto !important;
//   left: auto !important;
//   margin: 0;
// }
// .swiper-pagination-bullet {
//   padding: 5px 10px !important;
//   border-radius: 0 !important;
//   width: auto !important;
//   height: 30px !important;
//   text-align: center !important;
//   line-height: 30px !important;
//   font-size: 1.5rem !important;
//   color: #fff !important;
//   opacity: 1 !important;
//   background: #0000 !important;
// }
// .swiper-pagination-bullet-bullet-active {
//   border-bottom: 2px solid #ffab53 !important;
// }
.swiper-pagination-bullet-active {
  background-color: #fff !important;
}
.swiper-pagination {
  color: #fff !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 22px !important;
  width: 125% !important;
}
